
const Terms = () => {
  return (
    <>
      <div className="terms-wrapper mr-auto ml-auto container" style={{ marginBottom: '50px' }}>

        <div className="title text-3xl text-white mt-20">
          Terms of Use For Beamswap Website
        </div>
        <div className="paragraph text-white" style={{ marginTop: 60 }}>
          <p>[1] ACCEPTANCE OF TERMS OF USE AND LICENSE</p>
          <p>Beamswap Corporation. (herein referred to as the &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;) provides and makes available this website (the &ldquo;Website&rdquo;). Any use of this Website is subject to the terms and conditions contained in this Terms of Use Agreement (the &ldquo;Agreement&rdquo;). Please read this Agreement carefully. By accessing, browsing, contributing to or using this Website, you acknowledge that you have read, understood, and agree to be bound by this Agreement. If you do not accept the terms and conditions of this Agreement, you shall not access, browse, contribute to, or otherwise use the Website.</p>

          <p>You understand and agree that we may change this Agreement at any time without prior notice. If we do this, we will post the changes to this Agreement on this page and will indicate at the top of this page the date this Agreement was last revised. You may read a current, effective copy of this Agreement at any time by selecting the &ldquo;Terms of Use&rdquo; link on the Website. The revised terms and conditions will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Website or changes made for legal reasons will be effective immediately. Any use of the Website after such an effective date shall constitute your acceptance of such revised terms and conditions. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing, contributing to, or otherwise using the Website.</p>


          <p>If you are under thirteen (13) years of age, you are not authorized to use the Website. In addition, if you are under eighteen (18) years of age, you may use the Website, only with the approval of your parent or guardian.</p>

          <p>You further hereby represent and warrant to Company that:</p>

          <p>(a) You are not trying to access the Website from, nor are you a citizen or resident of any jurisdiction the laws of which prohibit or conflict with the holding, sale or trading of GLINT tokens,</p>

          <p>(b) You are not trying to access the Website from the United States and its territories, Canada, Democratic People&rsquo;s Republic of Korea, Cuba, Syria, Iran, People&rsquo;s Republic of Crimea, and People&rsquo;s Republic of China;</p>

          <p>(c) &nbsp;You are not a &ldquo;Prohibited Person&rdquo;, meaning that you are not (i) a citizen or resident of a geographic area in which use of the Websitek, the GLINT tokens is prohibited by applicable law, decree, regulation, treaty, or administrative act, (ii) a citizen or resident of, or located in, a geographic area that is subject to U.S. or other applicable comprehensive country sanctions or embargoes, (iii) an individual, or an individual employed by or associated with an entity, identified on the U.S. Department of Commerce Denied Persons, Unverified, or Entity List, the U.S. Department of Treasury&rsquo;s Specially Designated Nationals or Blocked Persons or Foreign Sanctions Evaders Lists, or the U.S. Department of State&rsquo;s Debarred Parties List or the sanctions lists adopted by the United Nations and the European Union to such extent such sanctions are extended by the UK Government to its Overseas Territories, as such lists may be amended from time to time, or (iv) a person who acts, directly or indirectly, for a senior foreign political figure, any member of a senior foreign political figure&rsquo;s immediate family or any close associate of a senior foreign political figure. No person or entity that controls, is controlled by or under common control with, you is a Prohibited Person. No person having any direct or indirect beneficial interest in you is a Prohibited Person;</p>

          <p>(d) &nbsp;YOU WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR A CLASS WIDE ARBITRATION AGAINST BEAMSWAP CORPORATION, ANY PERSON OR ENTITY INVOLVED IN THE WEBSITE AND/OR WITH THE CREATION AND DISTRIBUTION OF GLINT TOKENS;</p>

          <p>(e) &nbsp;You are not a U.S. Person as defined in Regulation S under the Securities Act of 1933, as amended (&ldquo;U.S. Person&rdquo;) and you agree not to offer or sell the GLINT tokens (or create or maintain any derivative position equivalent thereto) in the U.S. or to or for the account or benefit of a U.S. Person;</p>

          <p>(f) &nbsp;No transfers, sales, or other dispositions of GLINT tokenss by you shall be in violation of applicable laws, regulatory requirements or rules;</p>

          <p>(g) &nbsp;You bear the sole responsibility to determine what tax implications your use of the Website may have for you; and</p>

          <p>(h) &nbsp;All of the above representations and warranties are, and will continue to be, true, complete, accurate and non-misleading from the time of your acceptance of these attestation and notwithstanding the use by you of the Website.</p>

          <p>Terms, you may not access or use the Website in order to access the Website</p>
          <p>[2] DISCLAIMER AND RISKS</p>
          <p>The Company can not be held responsible for the third parties software you have to use in order to use elements of the Website dependent on the blockchain technologies, wallets.</p>
          <p>2.3</p>

          <p>﻿Early-stage technology.</p>
          <p>﻿</p>
          <p>Cryptocurrency tokens are created and distributed using distributed ledger or blockchain technology. This technology is highly experimental; therefore, participation in cryptocurrency token sales is very risky. Issuers of tokens or virtual coins often use software, new technologies, and new ways of doing business that are in an early development stage and unproven. The software, technologies, and related businesses invested in by the entity issuing tokens could be unfit for their intended purpose and/or not work as effectively or as well as anticipated.</p>
          <p>2.5</p>
          <p>﻿Protocol-related risk.</p>
          <p>﻿</p>
          <p>The Website utilizes the Moonbeam Network. The project using them will be adversely affected by any malfunction, dysfunction, or abandonment of these protocols. Additionally, these protocols could be rendered less valuable or valueless by advances in cryptography or other technical advances. For more information please refer to&nbsp;<a href="https://moonbeam.network/networks/moonbeam/">https://moonbeam.network/networks/moonbeam/</a></p>

          <p>2.6</p>
          <p>Unproven software.</p>
          <p>﻿</p>
          <p>Cryptocurrency token use software and other technology that are likely to be in an early development stage and unproven, and there is normally no warranty that the process for receiving, use and ownership of tokens or virtual coins will be uninterrupted or error-free. Such software and other technology could contain weaknesses, vulnerabilities, or bugs that could cause serious problems, including but not limited to inability to use tokens or virtual coins and the partial or complete loss of tokens or virtual coins.</p>


          <p>Loss of your credentials.</p>
          <p>﻿</p>
          <p>If you lose your crypto-wallet credentials or they are stolen, tokens or virtual coins you purchased will be permanently lost. A private key, or a combination of private keys, is necessary to control and dispose of Tokens stored in your wallet. Loss of the private key(s) associated with your wallet will result in loss of tokens. Any third party that acquires the ability to access your private key(s), including by acquiring login credentials of a hosted wallet service you use, may be able to steal your tokens. If your crypto-wallet malfunctions or fails for any reason, including your own failure to properly maintain or use it, may also result in your tokens being lost. Failure to correctly follow the procedures set out in any token sale documentation for buying and receiving tokens, including providing an incorrect wallet address or an address that is not ERC compatible may result in token loss.</p>
          <p>2.10</p>
          <p>Cybercrime.</p>
          <p>﻿</p>
          <p>Cryptocurrencies and tokens are inherently subject to the risk of cybercrime, which is difficult to protect against. The software used in any aspect of a cryptocurrency token could be hacked, which could result in inability to use or outright loss of some or all cryptocurrency and token assets. Entities issuing tokens are unlikely to be required to insure their assets and may find it too difficult to do so given commercial conditions for such insurance.</p>
          <p>2.11</p>
          <p>Failure or Abandonment.</p>
          <p>﻿</p>
          <p>Any aspect of any cryptocurrency token abandoned or required to be restructured, become or remain technologically or commercially unsuccessful, or be shut down for many reasons, including, but not limited to, lack of interest by the public; statutory, regulatory, or other legal changes; lack of funding; and lack of commercial success due to competing projects. There is no assurance that any tokens or virtual coins you acquire will have the value expected, or any value, at the time you wish to use them. You should understand and accept that the ownership and use of tokens or virtual coins is very risky such that they could be or become unusable or valueless with respect to the exchange of information, services, or value with other token or virtual coin owners, and they typically cannot be exchanged or redeemed to the entity that issued the tokens in return for fiat or alternative cryptocurrencies.</p>
          <p>2.12</p>
          <p>Regulatory risk.</p>
          <p>﻿</p>
          <p>The sale or use of tokens or virtual coins could be prohibited under applicable securities law. It is possible that existing regulations could be applied, or new regulations could be enacted, affecting blockchain technology-based applications and sales of tokens or virtual coins such that any aspect of cryptocurrency tokens could be negatively affected, requiring its modification or discontinuance and potentially resulting in the loss of tokens or token value.</p>
          <p>2.13</p>
          <p>No statutory protection.</p>
          <p>﻿</p>
          <p>Tokens do not represent deposits and are not subject to any statutory insurance or guarantees. In the event of insolvency of an entity issuing tokens or any entity involved in a cryptocurrency token, there will be no protection in place to allow recovery of losses.</p>
          <p>2.14</p>
          <p>Poor management.</p>
          <p>﻿</p>
          <p>All decisions involving products or services of an entity issuing tokens or related or associated parties are typically made internally by the entity or related or associated party. Purchasers of tokens have no say in the governance of these companies. These decisions could adversely affect the platform on which your tokens are intended to be used and/or the utility of any tokens you own. Entities issuing tokens are subject to the normal legal, accounting, and tax standards, but they may be operated by persons with very limited business experience.</p>
          <p>2.15</p>
          <p>Lack of oversight.</p>
          <p>﻿</p>
          <p>Most token sales are not structured or intended as an offer of securities or a promotion, invitation, or solicitation for investment purposes. Token sales are not, therefore, subject to the offering requirements that apply to securities, including legal standards for prospectuses or other documentation. Investing in unregulated tokens does not involve independent review or oversight required by law for securities offerings, and the accounts of token offerors may not be subject to audit requirements.</p>
          <p>2.16</p>
          <p>Fraud.</p>
          <p>﻿</p>
          <p>Because cryptocurrency token are largely unregulated forms of crowdfunding, there is a risk that dishonest people will use them as get-rich-quick schemes and not even attempt to deliver on the promises and representations made at the time of sale of the tokens. You should assess the quality and credibility of the whole team involved in a cryptocurrency token, including professional advisors, because the risk of fraud is significant in this young untested sector.</p>
          <p>[3] THE DATA WE COLLECT</p>
          <p>By accessing the website you confirm that you are doing it in good faith, the resources which you use are legitimately obtained and used in compliance with your local legislation and these Terms. You acknowledge that you&rsquo;re engaging with the website in good faith, responsibly and in compliance with the requirements of these terms and your local legislation.</p>
          <p>[4] SECURE YOUR ACCESS</p>
          <p>You accept and agree that blockchain based elements of the Website such as Tokens will require you to use third parties&rsquo; products such as Metamask. It is solely your responsibility to secure your access to your devices, your wallet. Please protect your security keys, passwords, email or phone number as this information might be used to compromise your access to the Website and or its elements, the company does not agree to be held responsible for your negligence, you acknowledge that blockchain transactions authorized by criminals are not reversible and final.</p>
          <p>Please be advised to:</p>
          <p>a) do not use the same passwords;</p>
          <p>b) protect your password and any related secret information;</p>
          <p>c) do not share your passwords or security keys with anyone else, even with the company.</p>
          <p>We reserve the right to suspend or terminate your access to the Website, if your account is in violation of these Terms. It would not matter if termination of your account, or loss of your access to Metamask are a result of your negligence and disregard of IT security practices.</p>
          <p>The company can not be responsible for the losses of your NFTs or Tokens. The Company&rsquo;s liability is limited by the gross negligence or fraud on the company&rsquo;s side, any we are not liable for any loss or damage to you or any third party arising from your actions or failures to act to remain in compliance with these terms and your local legislation.</p>

          <p>[7] LIABILITY</p>

          <p>Company, or its officers, directors, employees and representatives are free from claims and suits against any and all losses, liabilities, expenses, damages, legal costs, claims or actions of any kind whatsoever arising or resulting from your use of the Website your violation of these Terms or your local legislation.</p>
          <p>[8] INTELLECTUAL PROPERTY RIGHTS AND CONTENT RULES</p>

          <p>8.1</p>

          <p>The Company owns title, and Intellectual Property Rights over the website and Beamswap Corporation solution, its &ldquo;look and feel&rdquo;.</p>
          <p>8.2</p>

          <p>The Website contain proprietary information that is protected by copyright.</p>
          <p>8.3</p>

          <p>You are prohibited to copy, modify, rent, lease, loan, sell, distribute, perform, display or create Derivative Works based on the Website, in whole or in part.</p>
          <p>8.4</p>

          <p>You are prohibited to recreate the look and feel of the website by utilizing designs, IT solutions, data, operations, internal documents, information collectively called Solution. Solution is owned by the Company, protected by the copyright, patents, international conventions and applicable laws.</p>
          <p>8.5</p>

          <p>Unless you receive a written consent from the Company you are not granted in any form with ownership over the Solution or parts of the Solution.</p>

          <p>8.6</p>

          <p>In case you shared with us your comments, ideas, and provided feedback, you agree that the company may use these materials without remuneration to you. Said feedbacks become an intellectual property of the company.</p>
          <p>8.7</p>
          <p>You are prohibited to:</p>

          <p>a) distribute, share or make available any Content that is in violation of these rules or your local legislation including but not limited to: infringing copyrights, offensive, violent or hate inducing, encouraging criminal behavior;</p>

          <p>b) distribute or assist in distribution of any form of software with a destructive and or fraudulent nature, such as spyware, malware, ransomware including but not limited to: viruses, worms, defects, Trojan horses, exploits;</p>


          <p>c) engage in the identity fraud or impersonations of any kind;</p>

          <p>d) to violate other user&rsquo;s privacy in any form;</p>


          <p>e) to promote or conspire to engage in illegal activities</p>
          <p>including but not limited to money laundering, an authorized financial services;</p>


          <p>f) to create distractions to alter with other user&rsquo;s positive experience in Metaverse;</p>


          <p>g) to use the Website in unauthorized commercial enterprises;</p>

          <p>h) create and distribute unauthorized marketing campaigns advertisement such as junk email, spam;</p>



          <p>r) reverse engineer or modify the Website;</p>


          <p>j) delete copyrights and trademarks;</p>


          <p>k) frame the Website in full or portions;</p>


          <p>l) stalk, harass, suggest sex, engage in inappropriate conduct with other users;</p>


          <p>m) to use software and hardware in order to retrieve user&rsquo;s data from the website, socials to use the data in unlawful activities;</p>


          <p>n) to cheat the competition by utilizing software and hardware providing with unjust advantages you or your conspirers;</p>


          <p>o) create false accounts;</p>


          <p>p) to gain unauthorized access to other user&rsquo;s accounts, wallets;</p>


          <p>q) to copycat the website.</p>



          <p>[9] EXTERNAL RESOURCES</p>

          <p>You will be provided with the links to the external resources while browsing the website. Use them carefully as they are outside of our control. You acknowledge that the company cannot be held responsible for all failures of the damages caused by the 3rd party resources and software.</p>
          <p>You acknowledge that the company will not be held responsible for the failures of the hardware you use to access the Website.</p>


          <p>[11] OUR COPYRIGHT EFFORT</p>

          <p>Please contact us at info@beamswap.io if you have information that our users generated a copyright infringing content and distribute it via our Website.</p>

          <p>To the extent possible, the Company may try to reach the would-be infringing party to forward your concerns.</p>
          <p>The Company will also, at its sole discretion, (i) block any kind of Content uploaded by users; (ii) suspend user Accounts; and/or (iii) request documents evidencing the right of the contributor to use Intellectual Property Rights embedded in the Content.</p>
          <p>[12] INITIAL DISPUTES</p>

          <p>Parties of this agreement agreed to practice negotiations and communications to resolve any disagreement or dispute, before utilizing arbitration or court.</p>

          <p>[13] ARBITRATION</p>

          <p>If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute resolution under the Initial Dispute Resolution provision begins, then either party may initiate binding arbitration at the BVI International Arbitration Centre.</p>
          <p>Specifically, any dispute that is not resolved under the Initial Dispute Resolution provision shall be finally settled under the Laws of British Virgin Islands</p>


        </div>
      </div>
      <div style={{ marginBottom: 50 }}>
        <div className="container mr-auto ml-auto flex items-center justify-center w-full mt-32 xl:mt-56">
          <div className="w-full flex xl:flex-row flex-col justify-between items-start mx-10 xl:mx-0">

            <div className="flex flex-col xl:w-1/5 mb-10 xl:mb-0">
              <img src="/footer-logo.svg" width={178} height={34} />
              <div className="my-6 text-jordyBlue">
                Decentralized exchange (DEX) with an automated market maker (AMM), providing liquidity and peer-to-peer transactions, built on the Moonbeam network.
              </div>
              <div className="flex gap-3 justify-start w-full">
                <a href="https://twitter.com/Beamswapio" target="_blank" rel='noreferrer'>
                  <img src="/twitter.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://t.me/Beamswap" target="_blank" rel='noreferrer'>
                  <img src="/telegram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://discord.gg/g38NH5TqQg" target="_blank" rel='noreferrer'>
                  <img src="/discord.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://medium.com/beamswap" target="_blank" rel='noreferrer'>
                  <img src="/medium.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://www.facebook.com/beamswap.io" target="_blank" rel='noreferrer'>
                  <img src="/facebook.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://www.instagram.com/beamswap.io/" target="_blank" rel='noreferrer'>
                  <img src="/instagram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
              </div>
            </div>

            <div className="flex xl:flex-row flex-col gap-10">
              <div>
                <div className="text-white semi text-sm mb-6">Support</div>
                <div className='flex flex-col gap-2'>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://docs.beamswap.io/' rel='noreferrer'>
                    Documentation
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='/terms' target="_blank" rel='noreferrer'>
                    Terms and Conditions
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='/privacy' target="_blank" rel='noreferrer'>
                    Privacy Policy
                  </a>
                </div>
              </div>
              <div>
                <div className="text-white semi text-sm mb-6">Product</div>
                <div className='flex flex-col gap-2'>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/' target="_blank" rel='noreferrer'>
                    Swap
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/exchange/pool' target="_blank" rel='noreferrer'>
                    Liquidity
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/farm' target="_blank" rel='noreferrer'>
                    Yield Farms
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/beamshare' target="_blank" rel='noreferrer'>
                    Beamshare
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://analytics.beamswap.io/' target="_blank" rel='noreferrer'>
                    Analytics
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/bridge' target="_blank" rel='noreferrer'>
                    Bridge
                  </a>
                  <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/locker' target="_blank" rel='noreferrer'>
                    Locker
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;