import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../App.css';

function FadeInSection(props: any) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    });
    observer.observe(domRef.current!)
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function Home() {
  return (
    <>
      <FadeInSection key={"beam"}>
        <div>
          <div className="container mr-auto ml-auto flex flex-col justify-center relative w-full">
            <div className="flex justify-between z-10 mt-10 w-full">
              <div className="flex items-center">
                <img src="/logo.svg" />
                <a href='/tokenomics.pdf' target="_blank" rel='noreferrer'>
                  <div className='text-textPrimary text-sm font-bold transition hover:text-aqua cursor-pointer ml-14 hidden xl:block'>
                    TOKENOMICS
                  </div>
                </a>
                <a href='https://docs.beamswap.io/' target="_blank" rel='noreferrer'>
                  <div className='text-textPrimary text-sm font-bold transition hover:text-aqua cursor-pointer ml-6 hidden xl:block'>
                    DOCS
                  </div>
                </a>
              </div>
              <div className="flex gap-4">
                <a className="text-white hover:bg-secondary text-center py-3 px-6 rounded-lg cursor-pointer transition hover:text-aqua semi bg-secondaryHover xl:block hidden" href='https://app.beamswap.io/' rel='noreferrer'>
                  Buy $GLINT
                </a>
                <a className="text-white hover:bg-secondary text-center py-3 px-6 rounded-lg cursor-pointer transition hover:text-aqua semi bg-secondaryHover" href='https://app.beamswap.io/' rel='noreferrer'>
                  Launch App
                </a>
              </div>
            </div>
            <img src="/bgtext.png" className='mr-auto ml-auto right-0 left-0 -mb-48 xl:-mb-32 mt-10 xl:-mt-5' />
            <div className='mt-32 -mb-10 xl:-mt-64'>
              <div className='flex gap-3 xl:mb-14 justify-center items-center'>
                <div className='text-sm text-white text-center xl:text-left semi whitespace-nowrap'>DEFI HUB ON</div>
                <img src="/moonbeam.svg" width={200} />
              </div>
              <div className='hidden w-3/4 mr-auto ml-auto gap-4 justify-center xl:flex'>
                <a className="text-white hover:bg-secondary text-center text-md py-5 rounded-2xl cursor-pointer transition hover:text-aqua bg-secondaryHover semi w-1/3" href='https://app.beamswap.io/' rel='noreferrer'>
                  Launch App
                </a>
                <a className="text-white hover:bg-secondary text-center text-md py-5 rounded-2xl cursor-pointer transition hover:text-aqua bg-secondaryHover semi w-1/3" href='https://linktr.ee/Beamswap' target="_blank" rel='noreferrer'>
                  Join Our Community
                </a>
              </div>
            </div>

            <div className='flex flex-col xl:flex-row justify-center items-center w-full mt-32'>
              {/* <div className='mb-8'>
                   <div className='text-md text-white text-center xl:text-left semi'>DEFI HUB ON</div>
                   <img src="/moonbeam.svg" />
                 </div> */}
              <div className='w-full mr-auto ml-auto flex flex-col gap-4 justify-center xl:hidden mb-14'>
                <a className="text-white hover:bg-secondary text-center text-sm py-4 rounded-2xl cursor-pointer transition hover:text-aqua bg-secondaryHover semi w-3/4 mr-auto ml-auto" href='https://app.beamswap.io/' rel='noreferrer'>
                  Launch App
                </a>
                <a className="text-white hover:bg-secondary text-center text-sm py-4 rounded-2xl cursor-pointer transition hover:text-aqua bg-secondaryHover semi w-3/4 mr-auto ml-auto" href='https://linktr.ee/Beamswap' target="_blank" rel='noreferrer'>
                  Join Our Community
                </a>
              </div>
              <div className="flex gap-6 justify-between xl:justify-end">
                <a href="https://t.me/Beamswap" target="_blank" rel='noreferrer'>
                  <img src="/telegram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://twitter.com/Beamswapio" target="_blank" rel='noreferrer'>
                  <img src="/twitter.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://discord.gg/g38NH5TqQg" target="_blank" rel='noreferrer'>
                  <img src="/discord.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://medium.com/beamswap" target="_blank" rel='noreferrer'>
                  <img src="/medium.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://www.facebook.com/beamswap.io" target="_blank" rel='noreferrer'>
                  <img src="/facebook.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
                <a href="https://www.instagram.com/beamswap.io/" target="_blank" rel='noreferrer'>
                  <img src="/instagram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>

      <FadeInSection key={"servicesff"}>
        <div style={{ marginBottom: 300 }}>
          <div className="container mr-auto ml-auto flex flex-col items-center justify-center gap-10 w-full mt-32 xl:mt-56">
            <div className="text-secondary text-5xl xl:text-9xl semi font-bold mb-14">Core features</div>
            <div className="flex flex-col xl:flex-row gap-10 w-full justify-center mx-10 xl:mx-0">
              <a className="bg-secondary rounded-3xl flex flex-col items-center gap-5 xl:w-1/4 transition hover:scale-105 hover:bg-aqua cursor-pointer py-14 mx-5 xl:mx-0" href="https://app.beamswap.io/" target="_blank" rel="noreferrer">
                <img src="/arrow.svg" />
                <div className='text-md text-white semi'>Swap Tokens</div>
              </a>
              <a className="bg-secondary rounded-3xl flex flex-col items-center gap-5 xl:w-1/4 transition hover:scale-105 hover:bg-aqua cursor-pointer py-14 mx-5 xl:mx-0" href="https://app.beamswap.io/exchange/pool" target="_blank" rel="noreferrer">
                <img src="/supply.svg" />
                <div className='text-md text-white semi'>Supply Liquidity</div>
              </a>
              <a className="bg-secondary rounded-3xl flex flex-col items-center gap-5 xl:w-1/4 transition hover:scale-105 hover:bg-aqua cursor-pointer py-14 mx-5 xl:mx-0" href="https://app.beamswap.io/farm" target="_blank" rel="noreferrer">
                <img src="/yield.svg" />
                <div className='text-md text-white semi'>Yield Farms</div>
              </a>
            </div>
          </div>
        </div>
      </FadeInSection>

      <FadeInSection key={"services"}>
        <div style={{ marginBottom: 200, minHeight: 768 }}>
          <div className="container mr-auto ml-auto flex items-center justify-center gap-10 w-full mt-32 xl:mt-56 mb-56">
            <div className='flex xl:flex-row flex-col gap-14'>
              <div className="flex flex-col gap-10 items-center xl:border xl:border-secondary hover:border-none rounded-3xl xl:w-1/3 group h-fit xl:hover:bg-primary xl:bg-transparent transition xl:hover:scale-110 p-10">
                <div className="text-white text-5xl semi text-center">stGLINT token</div>
                <img src="/feature-1.svg" />
                <div className='xl:hidden group-hover:block'>
                  <div className="text-white text-center">
                    You can allocate or stake stGLINT in various Beamswap v3 features and plug-ins to generate passive rewards.
                  </div>
                  <a href="https://app.beamswap.io/dashboard" target="_blank" rel="noreferrer">
                    <div className="text-white semi text-center w-4/5 py-2 cursor-pointer tranisiton bg-secondaryHover hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                      stGLINT
                    </div>
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center xl:border xl:border-secondary hover:border-none rounded-3xl xl:w-1/3 group h-fit xl:hover:bg-primary xl:bg-transparent transition xl:hover:scale-110 p-10">
                <div className="text-white text-5xl semi text-center">Integrated Bridge</div>
                <img src="/feature-2.svg" />
                <div className='xl:hidden group-hover:block'>
                  <div className="text-white text-center">
                    Bridge your cryptocurrencies from other EVM chains to Moonbeam and vice versa with Beamswap bridge.
                  </div>
                  <a href="https://app.beamswap.io/bridge" target="_blank" rel="noreferrer">
                    <div className="text-white semi text-center w-4/5 py-2 cursor-pointer tranisiton bg-secondaryHover hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                      Use Bridge
                    </div>
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center xl:border xl:border-secondary hover:border-none rounded-3xl xl:w-1/3 group h-fit xl:hover:bg-primary xl:bg-transparent transition xl:hover:scale-110 p-10">
                <div className="text-white text-5xl semi text-center">Variety of features</div>
                <img src="/feature-3.svg" className='-mt-8' />
                <div className='xl:hidden group-hover:block'>
                  <div className="text-white text-center">
                    Limit orders, portfolio tracker, charts and more! Take advantage of the features that Beamswap has to offer!
                  </div>
                  <a href="https://app.beamswap.io/" target="_blank" rel="noreferrer">
                    <div className="text-white semi text-center w-4/5 py-2 cursor-pointer tranisiton bg-secondaryHover hover:bg-secondary  mr-auto ml-auto rounded-xl mt-6">
                      Beamswap
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInSection>

      {/* <FadeInSection key={"servicess"}>
          <div style={{marginBottom: 300}}>
            <div className="container mr-auto ml-auto items-center justify-center w-full mt-32 xl:mt-56 relative">          
              <img src="/roadmap-bg.svg" className='absolute hidden xl:block opacity-70 -mt-24' />
              <div className="text-white text-5xl xl:text-8xl semi font-bold text-center mb-14 xl:mb-44">Roadmap 2022</div>
              <div className='flex flex-col gap-10 xl:gap-0 w-full'>              

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2">
                  <div className='text-jordyBlue text-3xl semi text-center xl:hidden mb-3'>
                      Q1
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3' style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 8px #314894'}}>
                    <img src="/line-right.svg" className='absolute hidden xl:block' style={{right: -166, top: 25}} />                    
                    <ul className='list-disc text-jordyBlue'>
                      <li>Beamswap IDO</li>
                      <li>$GLINT Token generation event</li>
                      <li>Beamswap launch on Moonbeam</li>
                      <li>DEX and AMM</li>
                      <li>Yield farming</li>
                      <li>Bridge</li>
                      <li>Liquidity locker</li>
                      <li>Faucet</li>
                      <li>Beamshare DEX Analytics</li>
                    </ul>
                  </div>
                  <div className='text-jordyBlue text-3xl semi w-1/3 text-right hidden xl:block'>
                    Q1
                  </div>
                </div>

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2 xl:ml-auto">
                  <div className='text-white text-3xl hidden xl:block semi w-1/3'>
                      Q2
                  </div>
                  <div className='text-white text-3xl semi text-center xl:hidden mb-3'>
                      Q2
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3 bg-linear-gradient-border'>
                    <img src="/line-left.svg" className='absolute hidden xl:block' style={{left: -155, top: 25}} />                    
                    <ul className='list-disc text-jordyBlue'>
                      <li>Launchpad</li>
                      <li>First $GLINT Token burn</li>
                      <li>Zap</li>
                      <li>Syrup pools</li>
                      <li>Portfolio tracker</li>
                      <li>Website update and stability improvements</li>
                      <li>Stable AMM</li>
                      <li className='text-white'>Add support for more languages</li>
                      <li className='text-white'>Limit orders</li>
                    </ul>
                  </div>                  
                </div>

                <div className="flex flex-col xl:flex-row  justify-center xl:justify-between xl:w-1/2">
                  <div className='text-white text-3xl semi text-center xl:hidden mb-3'>
                      Q3/Q4
                  </div>
                  <div className='p-10 relative border border-primary rounded-xl xl:w-2/3 bg-linear-gradient-border-full'>
                    <img src="/line-right.svg" className='absolute hidden xl:block' style={{right: -110, top: 25}} />
                    <ul className='list-disc text-jordyBlue'>
                      <li>Yield farming V2</li>
                      <li>UI Update</li>
                      <li>Lending and borrowing</li>
                      <li>Team expansion</li>
                      <li>Advanced trading</li>
                      <li className='text-white'>Governance</li>
                      <li className='text-white'>CEX Listing</li>
                      <li className='text-white'>NFT Marketplace</li>
                      <li className='text-white'>Prediction markets</li>
                      <li className='text-white'>Lottery</li>
                    </ul>
                  </div>
                  <div className='text-white hidden xl:block text-3xl semi w-1/3 text-right'>
                    Q3/Q4
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection> */}

      <FadeInSection key={"servicessss"}>
        <div style={{ marginBottom: 300 }}>
          <div className="container mr-auto ml-auto flex items-center justify-center gap-10 w-full mt-32 xl:mt-56">
            <div className="bg-primary gap-14 py-14 px-14 flex xl:flex-row flex-col justify-center rounded-2xl mx-7 xl:mx-0">
              <img src="/token-glow.png" className='mt-auto mb-auto hidden xl:block' />
              <div className="flex flex-col items-start justify-start">
                <div className='text-7xl text-center xl:text-left text-white semi'>
                  GLINT <span className="text-secondary">Token</span>
                </div>
                <img src="/token-glow.png" className='mt-auto mb-auto mr-auto ml-auto xl:hidden' />
                <div className="flex xl:flex-row flex-col gap-10 mt-14">
                  <div className='flex flex-col gap-4 items-center xl:items-start xl:w-1/3'>
                    <img src="/glint-1.png" />
                    <div className='text-center xl:text-left text-white'>
                      Participate in our Launchpad projects and increase your allocation with GLINT.
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 items-center xl:items-start xl:w-1/3'>
                    <img src="/glint-2.png" />
                    <div className='text-center xl:text-left text-white'>
                      Use GLINT for Governance! Participate in the important decisions.
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 items-center xl:items-start xl:w-1/3'>
                    <img src="/glint-3.png" />
                    <div className='text-center xl:text-left text-white'>
                      Stake GLINT to receive a portion of the DEX Fees.
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </FadeInSection>

      <FadeInSection key={"servicesas"}>
        <div style={{ marginBottom: 500 }}>
          <div className="container mr-auto ml-auto flex flex-col items-center justify-center gap-10 w-full mt-32 xl:mt-56">
            <div className="text-secondary text-5xl xl:text-7xl semi font-bold mb-10 text-center">Some Trusted Partners</div>
            <div className="flex gap-10 w-full justify-center w-full items-center flex-wrap">

              <a href="https://chain.link/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/chainlink.png" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://www.coinbase.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-9 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/coinbase.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://beefy.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/beefy.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://peckshield.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/peckshield.png" className='mr-auto ml-auto' width={140} height={110} />
              </a>

              <a href="https://www.braindex.io/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/braindex.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://wormhole.com/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/wormhole.svg" className='mr-auto ml-auto' width={180} height={120} />
              </a>


              <a href="https://blastapi.io/" target={"_blank"} className=" mr-auto ml-auto p-5 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/blast.png" className='mr-auto ml-auto' width={160} height={160} />
              </a>
              <a href="https://bird.money/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/bird.png" className='mr-auto ml-auto' width={150} height={150} />
              </a>
              <a href="https://ola.finance/" target={"_blank"} className=" mr-auto ml-auto p-5 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/landing-partners-ola.png" className='mr-auto ml-auto' width={110} height={110} />
              </a>
              <a href="https://www.diadata.org/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/landing-partners-dia.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>




              <a href="https://acala.network/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/acala.png" className='mr-auto ml-auto' width={110} height={110} />
              </a>


              <a href="https://subwallet.app/" target={"_blank"} className=" mr-auto ml-auto p-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/subwallet.png" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://subsquid.io/" target={"_blank"} className=" mr-auto ml-auto p-6 py-8 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/subsquid.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>

              <a href="https://supraoracles.com/" target={"_blank"} className=" mr-auto ml-auto p-6 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/supraoracles.svg" className='mr-auto ml-auto' width={110} height={110} />
              </a>


              <a href="https://rubic.exchange/" target={"_blank"} className=" mr-auto ml-auto p-6 py-7 transition rounded-xl hover:bg-aqua bg-secondary w-[90%] xl:w-[30%]">
                <img src="/images/rubic.svg " className='mr-auto ml-auto' width={110} height={110} />
              </a>


            </div>
          </div>
        </div>
      </FadeInSection>

      <FadeInSection key={"servicesd"}>
        <div style={{ marginBottom: 50 }}>
          <div className="container mr-auto ml-auto flex items-center justify-center w-full mt-32 xl:mt-56">
            <div className="w-full flex xl:flex-row flex-col justify-between items-start mx-10 xl:mx-0">

              <div className="flex flex-col xl:w-1/5 mb-10 xl:mb-0">
                <img src="/footer-logo.svg" width={178} height={34} />
                <div className="my-6 text-jordyBlue">
                  Decentralized exchange (DEX) with an automated market maker (AMM), providing liquidity and peer-to-peer transactions, built on the Moonbeam network.
                </div>
                <div className="flex gap-3 justify-start w-full">
                  <a href="https://twitter.com/Beamswapio" target="_blank" rel='noreferrer'>
                    <img src="/twitter.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                  <a href="https://t.me/Beamswap" target="_blank" rel='noreferrer'>
                    <img src="/telegram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                  <a href="https://discord.com/invite/beamswap" target="_blank" rel='noreferrer'>
                    <img src="/discord.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                  <a href="https://medium.com/beamswap" target="_blank" rel='noreferrer'>
                    <img src="/medium.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                  <a href="https://www.facebook.com/beamswap.io" target="_blank" rel='noreferrer'>
                    <img src="/facebook.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                  <a href="https://www.instagram.com/beamswap.io/" target="_blank" rel='noreferrer'>
                    <img src="/instagram.svg.svg" className='brt-0 brb-0 footer-hover' width={30} height={30} />
                  </a>
                </div>
              </div>

              <div className="flex xl:flex-row flex-col gap-10">
                <div>
                  <div className="text-white semi text-sm mb-6">Support</div>
                  <div className='flex flex-col gap-2'>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://docs.beamswap.io/' rel='noreferrer'>
                      Documentation
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='/terms' target="_blank" rel='noreferrer'>
                      Terms and Conditions
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='/privacy' target="_blank" rel='noreferrer'>
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div>
                  <div className="text-white semi text-sm mb-6">Product</div>
                  <div className='flex flex-col gap-2'>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/' target="_blank" rel='noreferrer'>
                      Swap
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/exchange/pool' target="_blank" rel='noreferrer'>
                      Liquidity
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/farm' target="_blank" rel='noreferrer'>
                      Yield Farms
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/beamshare' target="_blank" rel='noreferrer'>
                      Beamshare
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://analytics.beamswap.io/' target="_blank" rel='noreferrer'>
                      Analytics
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/bridge' target="_blank" rel='noreferrer'>
                      Bridge
                    </a>
                    <a className="text-jordyBlue hover:text-aqua cursor-pointer" href='https://app.beamswap.io/locker' target="_blank" rel='noreferrer'>
                      Locker
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </FadeInSection>
      {/* 
        <FadeInSection>
          <div className="container mr-auto ml-auto flex flex-col justify-center">
          </div>          
        </FadeInSection> */}

    </>
  );
}

export default Home;
